body { 
    padding-top: 90px;
    font-family: Lato, Open Sans;
 }
@media screen and (max-width: 768px) {
    body { padding-top: 80px; }
}

.secondaryColor {
    color: #DF7E54
}